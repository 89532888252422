import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { map, switchMap, concatMap, tap, catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { diffSeconds } from '../../../helpers/date';

import { AuthenticationService } from '../services/authentication.service';
import { LocalStorageService } from '../../../core/services/common/localstorage.service';
import { createErrorNotificationConfig } from '../../shared/helpers/functions';
import { AnalyticsService } from '@core/services/common/analytics.service';
import { AnalyticsEvent } from '@core/models/analyticsEvent';

import {
  LOGIN,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REFRESH_LOGIN_SUCCESS,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_LOGIN,
  REMEMBER_LOGIN,
  REFRESH_TOKEN,
  RESET_PASSWORD,
  CONFIRM_ACCOUNT,
  RESET_PASSWORD_REQUEST,
  RESET_LOGIN,
  AUTHENTICATION_EFFECT_NAVIGATION,
  CREATE_USER_ACCOUNT,
  CREATE_USER_ACCOUNT_ERROR,
  CREATE_USER_ACCOUNT_EMAIL_EXISTS,
  CREATE_USER_ACCOUNT_SUCCESSFUL,
  CREATE_BUSINESS_PROFILE,
  STORE_BUSINESS_PROFILE,
  CREATE_WAREHOUSE,
  STORE_WAREHOUSE,
  RESEND_CONFIRMATION_EMAIL,
  CREATE_USER_ACCOUNT_CONFIRMATION,
  CREATE_USER_ACCOUNT_AUTH,
  SIGN_UP_IN_PROGRESS,
} from '../actions/authentication';

import {
  INIT_APPLICATION as GLOBAL_INIT_APPLICATION,
  REFRESH_LOGIN as GLOBAL_REFRESH_LOGIN,
  LOGIN_SUCCESS as GLOBAL_LOGIN_SUCCESS,
  REFRESH_TOKEN_SUCCESS as GLOBAL_REFRESH_TOKEN_SUCCESS,
  ADD_NOTIFICATION as GLOBAL_ADD_NOTIFICATION,
  LOGOUT_SUCCESS as GLOBAL_LOGOUT_SUCCESS,
  LOGOUT as GLOBAL_LOGOUT,
} from '../actions/globals';

import { RESET_ORDERS } from '../../orders/actions/orders';

import {
  SIGNUP_AUTH as GLOBAL_SIGNUP_AUTH,
  THROW_RESPONSE_ERR,
} from '../../../core/actions/globals';
import { UserAccountStatus } from '../models/authentication.models';

@Injectable()
export class AuthenticationEffects {
  storageKey: string = 'auth';

  constructor(
    private actions: Actions<any>,
    private authenticationService: AuthenticationService,
    private storageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
  ) {}

  public login$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(LOGIN),
      map((action) => action.login),
      concatMap((credentials) => {
        const { username, password, remember } = credentials;
        return this.authenticationService.login({ username, password }).pipe(
          switchMap((response: any) => {
            this.setToken(response, remember);

            return [
              { type: LOGIN_SUCCESS },
              { type: GLOBAL_LOGIN_SUCCESS },
              remember && { type: REMEMBER_LOGIN },
            ].filter((x) => x);
          }),
          catchError((error: any) => {
            const notification = createErrorNotificationConfig(error);
            return [{ type: GLOBAL_ADD_NOTIFICATION, notification }, { type: RESET_LOGIN }];
          }),
        );
      }),
    ),
  );

  public createUserAccount$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(CREATE_USER_ACCOUNT),
      map((action) => action.userAccountData),
      concatMap((userAccountData) => {
        return this.authenticationService.signUp(userAccountData).pipe(
          switchMap((response: any) => {
            this.setToken(response.data);
            const event = new AnalyticsEvent(
              'signup',
              'click',
              'signup_page_signup',
              'in_progress',
              1,
              2,
            );
            this.analyticsService.logEvent(event);
            return [{ type: CREATE_USER_ACCOUNT_SUCCESSFUL }, { type: SIGN_UP_IN_PROGRESS }];
          }),
          catchError((error: any) => {
            if (error?.error?.errors?.[0]?.detail === 'The email has already been taken.') {
              return [{ type: CREATE_USER_ACCOUNT_EMAIL_EXISTS }];
            } else {
              let notification = createErrorNotificationConfig(error);
              return [
                { type: GLOBAL_ADD_NOTIFICATION, notification },
                { type: CREATE_USER_ACCOUNT_ERROR },
              ];
            }
          }),
        );
      }),
    ),
  );

  public refreshLogin$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(REFRESH_LOGIN, GLOBAL_REFRESH_LOGIN),
      switchMap(() => {
        const token = this.storageService.getItem(this.storageKey);

        if (token) {
          const isValidToken = this.isTokenValid(token);
          const aboutToExpire = this.tokenIsAboutToExpire(token);
          const url: string = window.location.pathname;
          const { remember } = token;

          const notification = {
            message:
              'Your session has expired due to inactivity. Please log in again to continue using myPargo. ',
            type: 'fade',
            class: 'info',
          };

          const needsToNavigate = url.includes('login');

          return [
            !isValidToken && { type: LOGOUT },
            isValidToken && { type: REFRESH_LOGIN_SUCCESS },
            isValidToken && { type: GLOBAL_LOGIN_SUCCESS },
            remember && { type: REMEMBER_LOGIN },
            remember && isValidToken && aboutToExpire && { type: REFRESH_TOKEN },
            isValidToken && { type: GLOBAL_REFRESH_TOKEN_SUCCESS },
            isValidToken && { type: GLOBAL_INIT_APPLICATION },
            !isValidToken && !needsToNavigate && { type: GLOBAL_ADD_NOTIFICATION, notification },
          ].filter((x) => x);
        }

        return [];
      }),
    ),
  );

  public refreshToken$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(REFRESH_TOKEN),
      concatMap(() => {
        const token = this.storageService.getItem(this.storageKey);
        const { access_token } = token;

        if (token && access_token) {
          return this.authenticationService.refreshToken({ token: access_token }).pipe(
            switchMap((response: any) => {
              const tokenIsset = this.setToken(response, true);

              return [tokenIsset && { type: REFRESH_TOKEN_SUCCESS }].filter((x) => x);
            }),
            tap(() => this.router.navigate(['/orders'])),
          );
        }
      }),
    ),
  );

  public logout$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(LOGOUT, GLOBAL_LOGOUT),
      concatMap(() => {
        this.storageService.removeItem(this.storageKey);

        return [
          { type: LOGOUT_SUCCESS },
          { type: GLOBAL_LOGOUT_SUCCESS },
          { type: AUTHENTICATION_EFFECT_NAVIGATION, url: '/login' },
          { type: RESET_ORDERS },
        ];
      }),
    ),
  );

  public confirmAccount$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(CONFIRM_ACCOUNT),
      map((action) => action.confirm),
      concatMap((confirm) => {
        return this.authenticationService.confirmAccount(confirm).pipe(
          switchMap((response: any) => {
            this.setToken(response.data);
            if (response.auto_sign_up) {
              const event = new AnalyticsEvent(
                'signup',
                'view',
                'account_confirmation',
                'in_progress',
                1,
                5,
              );
              this.analyticsService.logEvent(event);
              return [
                { type: SIGN_UP_IN_PROGRESS },
                { type: CREATE_USER_ACCOUNT_AUTH },
                { type: GLOBAL_SIGNUP_AUTH },
                { type: GLOBAL_INIT_APPLICATION },
              ];
            } else {
              return [
                { type: LOGIN_SUCCESS },
                { type: GLOBAL_LOGIN_SUCCESS },
                { type: GLOBAL_INIT_APPLICATION },
              ];
            }
          }),
          catchError((error: any) => {
            const actions = [];
            actions.push({ type: AUTHENTICATION_EFFECT_NAVIGATION, url: '/login' });
            if (error?.error?.errors?.[0]?.detail !== 'Confirmation code is invalid.') {
              const notification = createErrorNotificationConfig(error);
              actions.push({ type: GLOBAL_ADD_NOTIFICATION, notification });
            }
            return actions;
          }),
        );
      }),
    ),
  );

  public resetPasswordRequest$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(RESET_PASSWORD_REQUEST),
      map((action) => action.email),
      concatMap((email) => {
        return this.authenticationService.resetPasswordRequest(email).pipe(
          switchMap((response: any) => {
            const notification = {
              message: `An email has been sent to ${email}, with a reset link`,
              type: 'fade',
              class: 'success',
            };

            return [
              { type: GLOBAL_ADD_NOTIFICATION, notification },
              { type: AUTHENTICATION_EFFECT_NAVIGATION, url: '/login' },
            ].filter((x) => x);
          }),
        );
      }),
    ),
  );

  public resetPassword$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(RESET_PASSWORD),
      map((action) => action.reset),
      concatMap((reset) => {
        const { password, email } = reset;
        return this.authenticationService.reset(reset).pipe(
          switchMap((response: any) => {
            if (response.success) {
              return [{ type: LOGIN, login: { username: email, password } }].filter((x) => x);
            }
          }),
        );
      }),
    ),
  );

  public navigate$: any = createEffect(
    () =>
      this.actions.pipe(
        ofType<any>(AUTHENTICATION_EFFECT_NAVIGATION),
        tap((action) => this.router.navigate([action.url])),
      ),
    { dispatch: false },
  );

  public createBusinessProfile$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(CREATE_BUSINESS_PROFILE),
      map((action) => action.profileData),
      concatMap((profileData) => {
        return this.authenticationService.updateProfile(profileData).pipe(
          switchMap(() => {
            const event = new AnalyticsEvent(
              'signup',
              'click',
              'create_profile_submit',
              'in_progress',
              4,
              9,
            );
            this.analyticsService.logEvent(event);
            return [
              { type: STORE_BUSINESS_PROFILE, profileData },
              { type: AUTHENTICATION_EFFECT_NAVIGATION, url: '/create-warehouse' },
            ];
          }),
          catchError((error: any) => {
            // Todo: Remove this check once update-profile endpoint has been updated to support suppliers.
            // Currently, if a user gets promoted to a supplier on different device or browser, they cannot continue from
            // browser where they had started the sign up process.
            if (this.isForbiddenErrorResponse(error)) {
              return [
                { type: STORE_BUSINESS_PROFILE, profileData },
                { type: CREATE_USER_ACCOUNT_AUTH },
                { type: GLOBAL_SIGNUP_AUTH },
                { type: GLOBAL_INIT_APPLICATION },
              ];
            }
            return [
              { type: STORE_BUSINESS_PROFILE, profileData },
              { type: THROW_RESPONSE_ERR, error },
            ];
          }),
        );
      }),
    ),
  );

  public createWarehouse$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(CREATE_WAREHOUSE),
      map((action) => action.warehouseData),
      concatMap((warehouseData) => {
        return this.authenticationService.updateWarehouse(warehouseData).pipe(
          switchMap((response: any) => {
            const event = new AnalyticsEvent(
              'signup',
              'click',
              'create_warehouse_submit',
              'in_progress',
              5,
              11,
            );
            this.analyticsService.logEvent(event);
            if (this.isUserAccountConfirmed(response.data[0])) {
              return [
                { type: STORE_WAREHOUSE, warehouseData },
                { type: CREATE_USER_ACCOUNT_AUTH },
                { type: GLOBAL_SIGNUP_AUTH },
                { type: GLOBAL_INIT_APPLICATION },
                {
                  type: CREATE_USER_ACCOUNT_CONFIRMATION,
                  accountConfirmationStatus: UserAccountStatus.Confirmed,
                },
              ];
            }
            return [
              { type: STORE_WAREHOUSE, warehouseData },
              {
                type: CREATE_USER_ACCOUNT_CONFIRMATION,
                accountConfirmationStatus: UserAccountStatus.Unconfirmed,
              },
            ];
          }),
          catchError((error: any) => {
            // Todo: Remove this check once update-profile endpoint has been updated to support suppliers.
            // Currently, if a user gets promoted to a supplier on different device or browser, they cannot continue from
            // browser where they had started the sign up process.
            if (this.isForbiddenErrorResponse(error)) {
              return [
                { type: STORE_WAREHOUSE, warehouseData },
                { type: CREATE_USER_ACCOUNT_AUTH },
                { type: GLOBAL_SIGNUP_AUTH },
                { type: GLOBAL_INIT_APPLICATION },
                {
                  type: CREATE_USER_ACCOUNT_CONFIRMATION,
                  accountConfirmationStatus: UserAccountStatus.Confirmed,
                },
              ];
            }
            return [
              { type: STORE_WAREHOUSE, warehouseData },
              { type: THROW_RESPONSE_ERR, error },
            ];
          }),
        );
      }),
    ),
  );

  public resendConfirmationEmail$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(RESEND_CONFIRMATION_EMAIL),
      map((action) => action.email),
      concatMap((email) => {
        return this.authenticationService.resendConfirmationEmail(email).pipe(
          switchMap(() => {
            const event = new AnalyticsEvent(
              'signup',
              'click',
              'create-warehouse_dialog_resend',
              'in_progress',
              5,
              13,
            );
            this.analyticsService.logEvent(event);
            const notification = {
              message: `An account confirmation email has been sent to ${email}`,
              type: 'fade',
              class: 'success',
            };
            return [{ type: GLOBAL_ADD_NOTIFICATION, notification }].filter((x) => x);
          }),
        );
      }),
    ),
  );

  private setToken(response, remember?: boolean): boolean {
    const isLoggedIn = `${!!(response && response.access_token)}`;
    const access_token = isLoggedIn ? response.access_token : null;
    const expires_in = isLoggedIn ? response.expires_in : null;

    const auth = {
      isLoggedInStr: isLoggedIn,
      access_token,
      expires_in,
      remember,
      timestamp: Date(),
    };

    const stored = this.storageService.setItem(this.storageKey, auth);

    return stored;
  }

  private tokenIsAboutToExpire(token) {
    const currentExpIn = token ? parseInt(token.expires_in) : 0;
    const lastTimestamp = token ? new Date(token.timestamp) : null;
    return !!(this.getDateDiff(lastTimestamp) >= currentExpIn - 900);
  }

  private isTokenValid(token) {
    const currentExpIn = token ? parseInt(token.expires_in) : 0;

    const lastTimestamp = token ? new Date(token.timestamp) : null;

    if (currentExpIn && lastTimestamp) {
      return !!(this.getDateDiff(lastTimestamp) >= currentExpIn - 120) ? false : true;
    }

    return false;
  }

  private getDateDiff(timestamp: Date) {
    const today = new Date();
    return diffSeconds(today, timestamp);
  }

  private isUserAccountConfirmed(response: {
    user: { attributes: { confirmed: boolean } };
  }): boolean {
    return response?.user?.attributes?.confirmed;
  }

  private isForbiddenErrorResponse(error: any): boolean {
    const forbiddenResponseCode = 403;
    return error?.error?.statusCode === forbiddenResponseCode;
  }
}
