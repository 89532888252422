const VERSION = 'jenkins-eng-mypargo-staging-391';
const API_URL = 'https://api.mypargo.staging.pargo.co.za';
const PAYMENT_API_URL = 'https://api.staging.pargo.co.za';
const SENTRY_DSN = 'https://e6ce2e57798e4e269cfd86e5b71aefb8@o640290.ingest.sentry.io/6159254';
const GA_TRACKING_ID = 'G-3591Q4FBH2';
const X_FORWARDER = 'X-For-Staging';
const SHOPIFY_PLUGIN_URL = 'https://shopify.staging.pargo.co/';
const ANALYTICS_API_URL = 'https://analytics.monitoring.pargo.co/api/v1/analytics/ingest';

export const environment = {
  production: false,
  staging: true,
  analyticsApiUrl: ANALYTICS_API_URL,
  apiUrl: API_URL,
  paymentApiUrl: PAYMENT_API_URL,
  X_FORWARDER,
  version: VERSION,
  pargoMail: 'support@pargo.co.za',
  peachUrl: 'https://eu-test.oppwa.com/v1/paymentWidgets.js',
  gaTrackingID: GA_TRACKING_ID,
  returnsAppURL: 'https://returns.staging.pargo.co/',
  sentryDSN: SENTRY_DSN,
  shopifyPluginUrl: SHOPIFY_PLUGIN_URL,
  trackAndTraceUrl: 'https://track.staging.pargo.co.za/',
};
