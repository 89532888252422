import { createAction, props } from '@ngrx/store';

import { Filter, Order, OrdersPrintingStatus, Pagination, Sort } from '../models/orders';

export const GET_ORDERS = 'ORDERS/GET_ORDERS';
export const GET_ORDER = 'ORDERS/GET_ORDER';
export const UPDATE_ORDERS = 'ORDERS/UPDATE_ORDERS';
export const UPDATE_PAGINATION = 'ORDERS/ UPDATE_PAGINATION';
export const UPDATE_FILTERS = 'ORDERS/UPDATE_FILTERS';
export const CHANGE_ORDER_VIEW = 'ORDERS/CHANGE_ORDER_VIEW';
export const CLOSE_ORDER_DETAILS = 'ORDERS/CLOSE_ORDER_DETAILS';
export const SET_CURRENT_ORDER = 'ORDERS/SET_CURRENT_ORDER';
export const SET_SELECTED_ORDERS = 'ORDERS/SET_SELECTED_ORDERS';
export const SORT_ORDERS = 'ORDERS/SORT_ORDERS';
export const SELECT_ALL_ORDERS = 'ORDERS/SELECT_ALL_ORDERS';
export const DESELECT_ALL_ORDERS = 'ORDERS/DESELECT_ALL_ORDERS';
export const UPDATE_QUERY_PARAMS = 'ORDERS/UPDATE_QUERY_PARAMS';
export const PRINT_LABELS = 'ORDERS/PRINT_LABELS';
export const CONFIRM_PRINT_LABELS = 'ORDERS/CONFIRM_PRINT_LABELS';
export const CONFIRM_ORDERS = 'ORDERS/CONFIRM_ORDERS';
export const MIX_CONFIRM_PRINT_LABELS = 'ORDERS/MIX_CONFIRM_PRINT_LABELS';
export const TRHOW_ORDER_ERROR = 'ORDERS/TRHOW_ORDER_ERROR';
export const REFRESH_CREDITS = 'ORDERS/REFRESH_CREDITS';
export const CREATE_ORDER_MANIFEST = 'ORDERS/CREATE_ORDER_MANIFEST';
export const EXPORT_ORDERS = 'ORDERS/EXPORT_ORDERS';

export const REVALIDATE_REGION_ORDERS = 'ORDERS/REVALIDATE_REGION_ORDERS';
export const VALIDATED_REGION_ORDERS = 'ORDERS/VALIDATED_REGION_ORDERS';
export const RESET_REVALIDATED_REGION_ORDERS = 'ORDERS/RESET_REVALIDATED_REGION_ORDERS';

export const REJECT_RETURN = 'ORDERS/REJECT_RETURN';
export const REJECT_RETURN_FAILED = 'ORDERS/REJECT_RETURN_FAILED';

export const CANCEL_ORDER = 'ORDERS/CANCEL_ORDER';
export const CANCEL_ORDER_FAILED = 'ORDERS/CANCEL_ORDER_FAILED';

export const ORDER_UPDATED_SUCCESS = 'ORDERS/ORDER_UPDATED_SUCCESS';
export const ORDER_UPDATED_FAILED = 'ORDERS/ORDER_UPDATED_FAILED';
export const ORDER_UPDATED_UNTOUCHED = 'ORDERS/ORDER_UPDATED_UNTOUCHED';
export const UPDATE_ORDER_CHECK_DONE = 'ORDERS/UPDATE_ORDER_CHECK_DONE';
export const CLEAR_PENDING_CONFIRMATION = 'ORDERS/CLEAR_PENDING_CONFIRMATION';

export const ADD_TRANSACTIONS = 'ORDERS/ADD_TRANSACTIONS';
export const RESET_ORDERS = 'ORDERS/RESET_ORDERS';
export const UPDATE_ORDERS_PRINTED_STATUS = 'ORDERS/UPDATE_ORDERS_PRINTED_STATUS';

export const getOrders = createAction(
  GET_ORDERS,
  props<{
    filters?: Filter[];
    pagination?: {
      page?: number;
      from?: number | string;
      to?: number | string;
      limit?: number | string;
      currentPage?: number | string;
    };
  }>(),
);

export const getOrder = createAction(GET_ORDER, props<{ id: string }>());

export const updateOrders = createAction(
  UPDATE_ORDERS,
  props<{ orders: Order[]; concat?: boolean }>(),
);

export const updatePagination = createAction(
  UPDATE_PAGINATION,
  props<{ pagination: Pagination }>(),
);

export const updateFilters = createAction(UPDATE_FILTERS, props<{ filters: Filter[] }>());

export const setCurrentOrder = createAction(
  SET_CURRENT_ORDER,
  props<{ id: string; track?: boolean }>(),
);

export const changeOrderView = createAction(CHANGE_ORDER_VIEW, props<{ view: string }>());

export const closeOrderDetails = createAction(CLOSE_ORDER_DETAILS);

export const setSelectedOrders = createAction(SET_SELECTED_ORDERS, props<{ id: string }>());

export const sortOrders = createAction(SORT_ORDERS, props<{ sort: Sort }>());

export const updateQueryParams = createAction(UPDATE_QUERY_PARAMS, props<{ params: string }>());

export const updateOrderCheckDone = createAction(UPDATE_ORDER_CHECK_DONE);

export const selectAllOrders = createAction(SELECT_ALL_ORDERS);

export const deselectAllOrders = createAction(DESELECT_ALL_ORDERS);

export const throwOrderError = createAction(TRHOW_ORDER_ERROR, props<{ error: any }>());

export const printLabels = createAction(
  PRINT_LABELS,
  props<{ ids: string[]; counter: number; updatePrintingStatus: boolean }>(),
);

export const createManifest = createAction(CREATE_ORDER_MANIFEST, props<{ ids: string[] }>());

export const exportOrders = createAction(
  EXPORT_ORDERS,
  props<{ filters: Filter[]; pagination?: { page: number } }>(),
);

export const confirmOrders = createAction(CONFIRM_ORDERS, props<{ orders: object[] }>());

export const orderUpdatedSuccess = createAction(ORDER_UPDATED_SUCCESS, props<{ order: object }>());

export const orderUpdatedFailed = createAction(ORDER_UPDATED_FAILED, props<{ order: object }>());

export const clearPendingConfirmation = createAction(CLEAR_PENDING_CONFIRMATION);

export const revalidateRegionOrders = createAction(
  REVALIDATE_REGION_ORDERS,
  props<{ orders: Order[] }>(),
);

export const validatedRegionOrders = createAction(
  VALIDATED_REGION_ORDERS,
  props<{ orders: Order[] }>(),
);

export const resetRevalidatedRegionOrders = createAction(RESET_REVALIDATED_REGION_ORDERS);

export const orderUpdatedUntouched = createAction(
  ORDER_UPDATED_UNTOUCHED,
  props<{ order: object }>(),
);

export const confirmPrintLabels = createAction(CONFIRM_PRINT_LABELS, props<{ orders: object[] }>());

export const mixConfirmPrintLabels = createAction(
  MIX_CONFIRM_PRINT_LABELS,
  props<{ orders: object[] }>(),
);

export const addTransactions = createAction(ADD_TRANSACTIONS, props<{ transactions }>());

export const rejectReturn = createAction(REJECT_RETURN, props<{ id: string; reason: string }>());
export const cancelOrder = createAction(CANCEL_ORDER, props<{ id: string; reason: string }>());

export const rejectReturnFailed = createAction(REJECT_RETURN_FAILED);
export const cancelOrderFailed = createAction(CANCEL_ORDER_FAILED);
export const resetOrders = createAction(RESET_ORDERS);

export const updateOrdersPrintedStatus = createAction(
  UPDATE_ORDERS_PRINTED_STATUS,
  props<{ status: OrdersPrintingStatus }>(),
);

export const actions = {
  changeOrderView,
  confirmPrintLabels,
  confirmOrders,
  deselectAllOrders,
  getOrder,
  getOrders,
  printLabels,
  selectAllOrders,
  setCurrentOrder,
  setSelectedOrders,
  sortOrders,
  throwOrderError,
  updateFilters,
  updateOrders,
  updatePagination,
  updateQueryParams,
  closeOrderDetails,
  mixConfirmPrintLabels,
  addTransactions,
  createManifest,

  revalidateRegionOrders,
  resetRevalidatedRegionOrders,
  validatedRegionOrders,

  clearPendingConfirmation,
  orderUpdatedFailed,
  orderUpdatedSuccess,
  orderUpdatedUntouched,

  updateOrderCheckDone,
  rejectReturn,
  rejectReturnFailed,
  cancelOrder,
  cancelOrderFailed,
  resetOrders,
  updateOrdersPrintedStatus,
};
